<template>
  <v-toolbar
    v-scroll="onScroll"
    :color="color"
    :flat="!hasColor"
    :light="hasColor"
    app
  >
    <v-toolbar-side-icon
      class="hidden-md-and-up"
      @click="toggleDrawer"
    />
    <v-toolbar-title class="headline hidden-xs-only">
      <span class="font-weight-bold">
        IT Fabrik
      </span>
      <span class="font-weight-light">
        Doo
      </span>
    </v-toolbar-title>
    <v-spacer />
    <v-btn
      v-for="(link, i) in links"
      :key="i"
      :to="link[0]"
      class="font-weight-light hidden-sm-and-down"
      flat
      large
    >
      {{ link[1] }}
    </v-btn>
  </v-toolbar>
</template>

<script>
// Utilities
  import { mapMutations, mapState } from 'vuex'

  export default {
    data: () => ({
      hasColor: false
    }),

    computed: {
      ...mapState('app', ['links']),
      color () {
        return this.hasColor ? 'white' : 'transparent'
      }
    },

    methods: {
      ...mapMutations('app', ['toggleDrawer']),
      onScroll () {
        this.hasColor = window.scrollY > 100
      }
    }
  }
</script>
